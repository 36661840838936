
module.exports = {
  isProdData: process.env.IS_PROD === 'true',
  hostname: process.env.HOSTNAME,
  appName: process.env.APP_NAME,
  domain: process.env.DOMAIN,
  servicesApi: process.env.SERVICES_API,
  crossEthApi: process.env.CROSS_ETH_API,
  identiconServe: 'https://display.d.id/identicon/',
  didtop: process.env.DIDTOP,
  homepage: process.env.HOMEPAGE,
  dasBalance: process.env.DAS_BALANCE,
  ckbNode: process.env.CKB_NODE,
  reverseApi: process.env.REVERSE_API,
  openseaEthNFT: process.env.OPENSEA_ETH_NFT,
  subdidApp: process.env.SUBDID_APP,
  fiatTermsLink: process.env.FIAT_TERMS_LINK,
  nuxtCdnPublicPath: process.env.NUXT_CDN_PUBLIC_PATH,
  baseUrl: process.env.BASE_URL,
};
