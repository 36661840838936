import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _28f8605f = () => interopDefault(import('../src/pages/alias/index.vue' /* webpackChunkName: "pages/alias/index" */))
const _47e19599 = () => interopDefault(import('../src/pages/apps/index.vue' /* webpackChunkName: "pages/apps/index" */))
const _230d78a2 = () => interopDefault(import('../src/pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _ffd4695a = () => interopDefault(import('../src/pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _3962e704 = () => interopDefault(import('../src/pages/referral/index.vue' /* webpackChunkName: "pages/referral/index" */))
const _ba6aaaba = () => interopDefault(import('../src/pages/reg/index.vue' /* webpackChunkName: "pages/reg/index" */))
const _69778a3f = () => interopDefault(import('../src/pages/alias/select.vue' /* webpackChunkName: "pages/alias/select" */))
const _030cab58 = () => interopDefault(import('../src/pages/my/pending.vue' /* webpackChunkName: "pages/my/pending" */))
const _696ebae2 = () => interopDefault(import('../src/pages/referral/reward-records.vue' /* webpackChunkName: "pages/referral/reward-records" */))
const _5f8839c9 = () => interopDefault(import('../src/pages/alias/-/ConfirmDeleteOldDialog.vue' /* webpackChunkName: "pages/alias/-/ConfirmDeleteOldDialog" */))
const _38be94ae = () => interopDefault(import('../src/pages/alias/-/SubmittedDialog.vue' /* webpackChunkName: "pages/alias/-/SubmittedDialog" */))
const _ff2e5084 = () => interopDefault(import('../src/pages/my/-/AccountStatus.vue' /* webpackChunkName: "pages/my/-/AccountStatus" */))
const _9483e254 = () => interopDefault(import('../src/pages/my/-/AccountStatusFilter.vue' /* webpackChunkName: "pages/my/-/AccountStatusFilter" */))
const _7e731d4e = () => interopDefault(import('../src/pages/my/-/CanNotBeLockedTips.vue' /* webpackChunkName: "pages/my/-/CanNotBeLockedTips" */))
const _eaca6876 = () => interopDefault(import('../src/pages/my/-/ConvertToCkbDialog.vue' /* webpackChunkName: "pages/my/-/ConvertToCkbDialog" */))
const _64571093 = () => interopDefault(import('../src/pages/my/-/DotBitList.vue' /* webpackChunkName: "pages/my/-/DotBitList" */))
const _9f67701c = () => interopDefault(import('../src/pages/my/-/EthNftList.vue' /* webpackChunkName: "pages/my/-/EthNftList" */))
const _11695236 = () => interopDefault(import('../src/pages/my/-/ManageBitAccount.vue' /* webpackChunkName: "pages/my/-/ManageBitAccount" */))
const _cb5f93ac = () => interopDefault(import('../src/pages/my/-/ManageEthAccount.vue' /* webpackChunkName: "pages/my/-/ManageEthAccount" */))
const _760edb70 = () => interopDefault(import('../src/pages/my/-/MintCompleted.vue' /* webpackChunkName: "pages/my/-/MintCompleted" */))
const _f797160e = () => interopDefault(import('../src/pages/my/-/MintNft.vue' /* webpackChunkName: "pages/my/-/MintNft" */))
const _318def09 = () => interopDefault(import('../src/pages/my/-/MyDas.vue' /* webpackChunkName: "pages/my/-/MyDas" */))
const _c19f80fe = () => interopDefault(import('../src/pages/my/-/NoEthereumTips.vue' /* webpackChunkName: "pages/my/-/NoEthereumTips" */))
const _898064a0 = () => interopDefault(import('../src/pages/my/-/NoSupportDogeMintTips.vue' /* webpackChunkName: "pages/my/-/NoSupportDogeMintTips" */))
const _537971e4 = () => interopDefault(import('../src/pages/my/-/NoSupportTronMintTips.vue' /* webpackChunkName: "pages/my/-/NoSupportTronMintTips" */))
const _35ec9d64 = () => interopDefault(import('../src/pages/my/-/Processing.vue' /* webpackChunkName: "pages/my/-/Processing" */))
const _555fde64 = () => interopDefault(import('../src/pages/my/-/RegisteringAccounts.vue' /* webpackChunkName: "pages/my/-/RegisteringAccounts" */))
const _541f05f9 = () => interopDefault(import('../src/pages/my/-/Search.vue' /* webpackChunkName: "pages/my/-/Search" */))
const _352573c3 = () => interopDefault(import('../src/pages/referral/-/RewardRecords.vue' /* webpackChunkName: "pages/referral/-/RewardRecords" */))
const _797e3aca = () => interopDefault(import('../src/pages/reg/-/AccountInfo.vue' /* webpackChunkName: "pages/reg/-/AccountInfo" */))
const _dd6a59e4 = () => interopDefault(import('../src/pages/reg/-/AccountStatus.vue' /* webpackChunkName: "pages/reg/-/AccountStatus" */))
const _382e498c = () => interopDefault(import('../src/pages/reg/-/CouponCodeTips.vue' /* webpackChunkName: "pages/reg/-/CouponCodeTips" */))
const _1932dd48 = () => interopDefault(import('../src/pages/reg/-/ExplorerSearch.vue' /* webpackChunkName: "pages/reg/-/ExplorerSearch" */))
const _61efe290 = () => interopDefault(import('../src/pages/account/register/-/AboutEthNFTDialog.vue' /* webpackChunkName: "pages/account/register/-/AboutEthNFTDialog" */))
const _23cba709 = () => interopDefault(import('../src/pages/account/register/-/DasBalanceInsufficientDialog.vue' /* webpackChunkName: "pages/account/register/-/DasBalanceInsufficientDialog" */))
const _5726dc37 = () => interopDefault(import('../src/pages/account/register/-/DiscountTabs.vue' /* webpackChunkName: "pages/account/register/-/DiscountTabs" */))
const _1391ccb8 = () => interopDefault(import('../src/pages/account/register/-/PwBalanceInsufficientDialog.vue' /* webpackChunkName: "pages/account/register/-/PwBalanceInsufficientDialog" */))
const _90057620 = () => interopDefault(import('../src/pages/account/register/-/SignatureErrorDialog.vue' /* webpackChunkName: "pages/account/register/-/SignatureErrorDialog" */))
const _415b84f5 = () => interopDefault(import('../src/pages/account/register/-/UpdatedRegistrationPeriodDialog.vue' /* webpackChunkName: "pages/account/register/-/UpdatedRegistrationPeriodDialog" */))
const _60e17696 = () => interopDefault(import('../src/pages/account/register/status/-/ConfirmList.vue' /* webpackChunkName: "pages/account/register/status/-/ConfirmList" */))
const _55b71a8b = () => interopDefault(import('../src/pages/account/register/status/-/EthMarketplace.vue' /* webpackChunkName: "pages/account/register/status/-/EthMarketplace" */))
const _e5f7e580 = () => interopDefault(import('../src/pages/account/register/status/-/RegisterGuideCard.vue' /* webpackChunkName: "pages/account/register/status/-/RegisterGuideCard" */))
const _c06a3f76 = () => interopDefault(import('../src/pages/account/register/status/_account.vue' /* webpackChunkName: "pages/account/register/status/_account" */))
const _13456a04 = () => interopDefault(import('../src/pages/account/register/_account.vue' /* webpackChunkName: "pages/account/register/_account" */))
const _1bc6f57e = () => interopDefault(import('../src/pages/alias/set/_account.vue' /* webpackChunkName: "pages/alias/set/_account" */))
const _b39bcf26 = () => interopDefault(import('../src/pages/referral/invitation-link/_account.vue' /* webpackChunkName: "pages/referral/invitation-link/_account" */))
const _b8e34162 = () => interopDefault(import('../src/pages/reg/account/_account.vue' /* webpackChunkName: "pages/reg/account/_account" */))
const _629bf05e = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/bit/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alias",
    component: _28f8605f,
    name: "alias"
  }, {
    path: "/apps",
    component: _47e19599,
    name: "apps"
  }, {
    path: "/debug",
    component: _230d78a2,
    name: "debug"
  }, {
    path: "/my",
    component: _ffd4695a,
    name: "my"
  }, {
    path: "/referral",
    component: _3962e704,
    name: "referral"
  }, {
    path: "/reg",
    component: _ba6aaaba,
    name: "reg"
  }, {
    path: "/alias/select",
    component: _69778a3f,
    name: "alias-select"
  }, {
    path: "/my/pending",
    component: _030cab58,
    name: "my-pending"
  }, {
    path: "/referral/reward-records",
    component: _696ebae2,
    name: "referral-reward-records"
  }, {
    path: "/alias/-/ConfirmDeleteOldDialog",
    component: _5f8839c9,
    name: "alias---ConfirmDeleteOldDialog"
  }, {
    path: "/alias/-/SubmittedDialog",
    component: _38be94ae,
    name: "alias---SubmittedDialog"
  }, {
    path: "/my/-/AccountStatus",
    component: _ff2e5084,
    name: "my---AccountStatus"
  }, {
    path: "/my/-/AccountStatusFilter",
    component: _9483e254,
    name: "my---AccountStatusFilter"
  }, {
    path: "/my/-/CanNotBeLockedTips",
    component: _7e731d4e,
    name: "my---CanNotBeLockedTips"
  }, {
    path: "/my/-/ConvertToCkbDialog",
    component: _eaca6876,
    name: "my---ConvertToCkbDialog"
  }, {
    path: "/my/-/DotBitList",
    component: _64571093,
    name: "my---DotBitList"
  }, {
    path: "/my/-/EthNftList",
    component: _9f67701c,
    name: "my---EthNftList"
  }, {
    path: "/my/-/ManageBitAccount",
    component: _11695236,
    name: "my---ManageBitAccount"
  }, {
    path: "/my/-/ManageEthAccount",
    component: _cb5f93ac,
    name: "my---ManageEthAccount"
  }, {
    path: "/my/-/MintCompleted",
    component: _760edb70,
    name: "my---MintCompleted"
  }, {
    path: "/my/-/MintNft",
    component: _f797160e,
    name: "my---MintNft"
  }, {
    path: "/my/-/MyDas",
    component: _318def09,
    name: "my---MyDas"
  }, {
    path: "/my/-/NoEthereumTips",
    component: _c19f80fe,
    name: "my---NoEthereumTips"
  }, {
    path: "/my/-/NoSupportDogeMintTips",
    component: _898064a0,
    name: "my---NoSupportDogeMintTips"
  }, {
    path: "/my/-/NoSupportTronMintTips",
    component: _537971e4,
    name: "my---NoSupportTronMintTips"
  }, {
    path: "/my/-/Processing",
    component: _35ec9d64,
    name: "my---Processing"
  }, {
    path: "/my/-/RegisteringAccounts",
    component: _555fde64,
    name: "my---RegisteringAccounts"
  }, {
    path: "/my/-/Search",
    component: _541f05f9,
    name: "my---Search"
  }, {
    path: "/referral/-/RewardRecords",
    component: _352573c3,
    name: "referral---RewardRecords"
  }, {
    path: "/reg/-/AccountInfo",
    component: _797e3aca,
    name: "reg---AccountInfo"
  }, {
    path: "/reg/-/AccountStatus",
    component: _dd6a59e4,
    name: "reg---AccountStatus"
  }, {
    path: "/reg/-/CouponCodeTips",
    component: _382e498c,
    name: "reg---CouponCodeTips"
  }, {
    path: "/reg/-/ExplorerSearch",
    component: _1932dd48,
    name: "reg---ExplorerSearch"
  }, {
    path: "/account/register/-/AboutEthNFTDialog",
    component: _61efe290,
    name: "account-register---AboutEthNFTDialog"
  }, {
    path: "/account/register/-/DasBalanceInsufficientDialog",
    component: _23cba709,
    name: "account-register---DasBalanceInsufficientDialog"
  }, {
    path: "/account/register/-/DiscountTabs",
    component: _5726dc37,
    name: "account-register---DiscountTabs"
  }, {
    path: "/account/register/-/PwBalanceInsufficientDialog",
    component: _1391ccb8,
    name: "account-register---PwBalanceInsufficientDialog"
  }, {
    path: "/account/register/-/SignatureErrorDialog",
    component: _90057620,
    name: "account-register---SignatureErrorDialog"
  }, {
    path: "/account/register/-/UpdatedRegistrationPeriodDialog",
    component: _415b84f5,
    name: "account-register---UpdatedRegistrationPeriodDialog"
  }, {
    path: "/account/register/status/-/ConfirmList",
    component: _60e17696,
    name: "account-register-status---ConfirmList"
  }, {
    path: "/account/register/status/-/EthMarketplace",
    component: _55b71a8b,
    name: "account-register-status---EthMarketplace"
  }, {
    path: "/account/register/status/-/RegisterGuideCard",
    component: _e5f7e580,
    name: "account-register-status---RegisterGuideCard"
  }, {
    path: "/account/register/status/:account?",
    component: _c06a3f76,
    name: "account-register-status-account"
  }, {
    path: "/account/register/:account?",
    component: _13456a04,
    name: "account-register-account"
  }, {
    path: "/alias/set/:account?",
    component: _1bc6f57e,
    name: "alias-set-account"
  }, {
    path: "/referral/invitation-link/:account?",
    component: _b39bcf26,
    name: "referral-invitation-link-account"
  }, {
    path: "/reg/account/:account?",
    component: _b8e34162,
    name: "reg-account-account"
  }, {
    path: "/",
    component: _629bf05e,
    name: "index"
  }, {
    path: "/r",
    redirect: "/",
    name: "referralLink"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
