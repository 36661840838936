/* eslint-disable import/order */
import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'
import { BrowserTracing } from '~@sentry/tracing'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:"00524a90e2e9e4128eb9880874daf5fed1935995",
    dsn:"https:\u002F\u002F020dbfc07c8945ef81e9c2a3765580ef@o4504755411812352.ingest.sentry.io\u002F4504756285931520",
    environment:"production",
    tracesSampleRate:1,
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver(),
    new RewriteFrames(),
  ]

  // eslint-disable-next-line prefer-regex-literals
  const { browserTracing, vueOptions, ...tracingOptions } = {"tracesSampleRate":1,"browserTracing":{},"vueOptions":{"trackComponents":true}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: Sentry.vueRouterInstrumentation(ctx.app.router) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
